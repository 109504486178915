export class FilingPeriod {
    companyId?: number;
    mileageDataDocumentId?: number | null;
    importedDocumentId?: number | null;
    fuelDataDocumentId?: number | null;
    id?: number;
    year?: number;
    quarterNumber?: number;
    startPeriodDate?: string | null;
    endPeriodDate?: string | null;
    actualFilingDate?: string | null;
    deadlineFilingDate?: string | null;
    filingPeriodData?: FiingPeriodList[];
    isFuelIntegrationAvailable?: boolean;
    isMileageIntegrationAvailable?: boolean;
    permitFilings?: PermitList[];
    isMileageImported?: boolean;
    isFuelImported?: boolean;
    constructor(init?: Partial<FilingPeriod>) {
        Object.assign(this, init);
    }
}

export class FiingPeriodList {
    localId: number = 0;
    filingPeriodId: number;
    assetTruckId?: number;
    assetCode?: string;
    permitTruckLicenseNumber?: string;
    permitFilingId?: number;
    state?: string;
    distance?: number | string | null;
    fuelQuantity?: number | string | null;
    reeferQuantity?: number | string | null;
    fuelAmount?: number | string | null;
    reeferAmount?: number | string | null;

    distanceDisp?: number | string | null = 0;
    fuelQuantityDisp?: number | string | null = 0;
    reeferQuantityDisp?: number | string | null = 0;
    fuelAmountDisp?: number | string | null = 0;
    reeferAmountDisp?: number | string | null = 0;

    id: number;
    constructor(init?: Partial<FiingPeriodList>) {
        Object.assign(this, init);
        this.id = 0;
        this.localId = 0;
        this.filingPeriodId = 0;
        this.distanceDisp = 0;
        this.fuelQuantityDisp = 0;
        this.reeferQuantityDisp = 0;
        this.fuelAmountDisp = 0;
        this.reeferAmountDisp = 0;
    }
}

export class FilingPeriodFpList {
    year?: number;
    quarterNumber?: number;
    totalMileage?: number | string | null;
    totalGallons?: number | string | null;
    mpg?: string;
    allFields?: string;
    quarterStr: string = '';
    constructor(init?: Partial<FilingPeriodFpList>) {
        this.quarterStr = '';
        Object.assign(this, init);
    }
}

export interface PermitList {
    permitId: number;
    filingPeriodId: number;
    accountNumber: string;
    permitType: PermitTypeEnum;
    id: number;
}

export enum PermitTypeEnum {
    IFTA = 'IFTA',
    NY = 'NY',
    KYU = 'KYU',
    NM = 'NM',
    OR = 'OR',
}

export interface YearAndQuarter {
    year: number;
    quarterNumber: number;
    startPeriodDate: string;
}

export interface FilingPermitList {
    permitId: number;
    filingPeriodId: number;
    state: string;
    totalDistance: number | string | null;
    status: FilingPermitStatusEnum;
    fuelQuantity: number | string | null;
    reeferQuantity: number | string | null;
    totalDueAmount: number | string | null;
    permitType: PermitTypeEnum;
    startPeriodDate: string;
    endPeriodDate: string;
    accountNumber: string;
    id: number;
    taxReturnReportDocumentId: number | null;
    filingDate: string | null;
}

export enum FilingPermitStatusEnum {
    Filed = 'Filed',
    NotFiled = 'NotFiled',
    NotFiledDateExpired = 'NotFiledDateExpired',
}

export class FilingPermit {
    permitId?: number;
    filingPeriodId?: number;
    state?: string;
    totalDistance?: number | string;
    fuelQuantity?: number | string;
    reeferQuantity?: number | string;
    taxReturnReportDocumentId?: number;
    mpg?: number | string;
    taxableDistance?: number | string;
    taxPaidQuantity?: number | string;
    netTaxableQuantity?: number | string;
    netTaxDueCreditAmount?: number | string;
    totalDueAmount?: number | string;
    filingDate?: string | null;
    permitFilingDetails?: FiingPeriodList[];
    permitType?: PermitTypeEnum;
    startPeriodDate?: string | null;
    endPeriodDate?: string | null;
    accountNumber?: string;
    id?: number;

    constructor(init?: Partial<FilingPermit>) {
        Object.assign(this, init);
    }
}

export class MappedColumns {
    year?: number;
    quarterNumber?: number;
    excelDocumentId?: number;
    mappedColumns?: {
        sourceColumn: string;
        targetColumn: string;
    }[];
    constructor(init?: Partial<MappedColumns>) {
        Object.assign(this, init);
    }
}

export interface ColumnsFromApi {
    dbColumnName: string;
    aliasColumnName: string;
}

export interface FilingImporListtModalData {
    // tableData: ColumnTable<any>[];
    getData: () => Promise<any>;
    importFunc: (mappedData: MappedColumns) => Promise<any>;
    year: number;
    quarter: number;
}
